<template>
  <div class="page-row">
    <category-title :category="category" />

    <div
      v-for="(row, rowIndex) in $ebsn.meta(
        category,
        'template_model.ROW_CONFIG.rows'
      )"
      :key="rowIndex"
      :style="
        `${row.style}; ${
          row.backgroundColor
            ? 'backgroundColor: ' + row.backgroundColor + ';'
            : ''
        }`
      "
    >
      <!-- <v-container
        v-if="row.container"
        class="container-row"
        :class="`row-number-${rowIndex}`"
      >
        <ebsn-style
          v-if="row.heights"
          :target="{
            metaData: {
              css: templateHelper.calcHeightStyle(
                row.heights,
                `.row-number-${rowIndex}`
              )
            }
          }"
          path="css"
        />
        <category-block
          :position="row.position"
          :target="category"
          :container="false"
        />
      </v-container> -->
      <!-- <div v-else class="category-block-row" :class="`row-number-${rowIndex}`"> -->
      <div class="category-block-row" :class="`row-number-${rowIndex}`">
        <ebsn-style
          v-if="row.heights"
          :target="{
            metaData: {
              css: templateHelper.calcHeightStyle(
                row.heights,
                `.row-number-${rowIndex}`
              )
            }
          }"
          path="css"
        />
        <category-block
          :position="row.position"
          :target="category"
          :container="row.container"
        />
      </div>
    </div>

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_TEXT"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import * as templateHelper from "@/components/category/templateHelper";

import { mapActions } from "vuex";
import get from "lodash/get";

export default {
  name: "PageRow",
  mixins: [category, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      key: 1,
      templateHelper: templateHelper
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    })
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.category.metaData?.category_info?.HEADER_IMAGE ||
            this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "coopshop.it"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
