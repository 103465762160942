var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-row"},[_c('category-title',{attrs:{"category":_vm.category}}),_vm._l((_vm.$ebsn.meta(
      _vm.category,
      'template_model.ROW_CONFIG.rows'
    )),function(row,rowIndex){return _c('div',{key:rowIndex,style:(`${row.style}; ${
        row.backgroundColor
          ? 'backgroundColor: ' + row.backgroundColor + ';'
          : ''
      }`)},[_c('div',{staticClass:"category-block-row",class:`row-number-${rowIndex}`},[(row.heights)?_c('ebsn-style',{attrs:{"target":{
          metaData: {
            css: _vm.templateHelper.calcHeightStyle(
              row.heights,
              `.row-number-${rowIndex}`
            )
          }
        },"path":"css"}}):_vm._e(),_c('category-block',{attrs:{"position":row.position,"target":_vm.category,"container":row.container}})],1)])}),_c('ebsn-meta',{staticClass:"category-block-footer",attrs:{"target":_vm.category,"path":"category_info.FOOTER_TEXT","tag":"div"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }